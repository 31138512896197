import React from 'react'
import './styles.scss'

function SaveTheDate(props: React.HTMLAttributes<HTMLElement>) {
  return (
    <div className="saveTheDate">
    </div>
  )
}

export default SaveTheDate
